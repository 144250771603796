import React, { useState, useEffect } from 'react';
import { IFilm } from '../../Interfaces/Film';
import { Skeleton } from '@mui/material';

import './FilmCards.css';

import Routes from "../../Utils/Routes.json"

// Interface

import { ISearchFilm } from '../../Interfaces/FilmSearch';
import { searchFilmDataRequest } from '../../Utils/Requests/RequestRegister';
import { Link } from 'react-router-dom';
import { NumberFormat } from '../../Components/NumberFormat';

interface IFilmCardsProps {
    filmData?: ISearchFilm;
    horizontal: boolean;
    className?: string;
    onHover?: (film: IFilm) => void;
    films?: IFilm[];
    continueLoading?: boolean;
    type?: string
}

function FilmCards({ filmData, horizontal, className, onHover, films, continueLoading, type }: IFilmCardsProps) {


    const [loading, setLoading] = useState<boolean>(true);
    const [filmList, setFilmList] = useState<IFilm[]>([]);
    const [increment, setIncrement] = useState<number>(0);

    const searchFilm = async () => {

        if (!filmData) {
            return
        }

        let searchParams = "";
        for (const key in filmData) {
            if (Object.prototype.hasOwnProperty.call(filmData, key)) {
                const value = filmData[key as keyof ISearchFilm]; // Type assertion
                if (searchParams.length === 0) {
                    searchParams += `?${key}=${value}`;
                } else {
                    searchParams += `&${key}=${value}`;
                }
            }
        }
        try {
            const searchFilmResponse = await searchFilmDataRequest(searchParams);

            if (searchFilmResponse.status === "success") {
                if (continueLoading) {
                    setFilmList(prevFilmList => [...prevFilmList, ...searchFilmResponse.films]);
                } else {
                    setFilmList(searchFilmResponse.films);
                }
            }

            if (searchFilmResponse.films.length > 0) {
                setLoading(false);
            }
        } catch (error) {
        }
    };

    const getFilmRating = (film: IFilm): string => {
        if (typeof film.like !== "number" || typeof film.dislike !== "number") {
            return "N/A"; // Return "Not Available" if like or dislike is not a number
        }

        if (film.dislike === 0) {
            return "10"; // Return "10" if there are no dislikes to avoid division by zero
        }

        const rating = film.like / film.dislike;

        // Round the rating to 2 decimal places
        const roundedRating = rating.toFixed(2);

        return roundedRating;
    }


    useEffect(() => {
        if (films) {
            setLoading(false)
            return
        }

        searchFilm();
    }, [filmData]);

    useEffect(() => {
        if (onHover && filmList.length > 0) {
            onHover(filmList[increment]);
            setInterval(() => {
                if (increment === filmList.length - 1) {
                    setIncrement((prevData) => {
                        return 0;
                    });
                } else {
                    setIncrement(increment + 1)
                }
            }, 2000);
        }
    }, [filmList.length]);

    return (
        <>
            {loading ? (
                <>
                    {filmData && [...Array(filmData.skeletonAmount || 20)].map((_, index) => (
                        <Skeleton
                            key={index}
                            className={`${className || "film-skeleton"} ${horizontal ? 'horizontal' : 'vertical'}`}
                            sx={{ background: 'gray' }}
                        />
                    ))}
                </>
            ) : (
                <>
                    {filmList.length > 0 && (
                        filmList.map((film: IFilm) => (
                            <>

                                {type === "show_data" ? (
                                    <Link to={{ pathname: `/film/${film.id}` }} state={{ film: film }} key={film.id} className={`film-card  ${horizontal ? 'horizontal' : 'vertical'}`} onMouseEnter={() => {
                                        onHover && onHover(film);
                                    }}>
                                        <img src={Routes.Domain + Routes.imagesFilm + film.film_banner} alt={film.film_name} />
                                        <span className='film-title'>{film.film_name}</span>
                                        <span className='film-rating'>{getFilmRating(film)}</span>
                                        <h1 className='film-views'>{film.views ? NumberFormat(film.views) : ""}</h1>
                                    </Link>
                                ) : (
                                    <Link to={{ pathname: `/film/${film.id}` }} state={{ film: film }} key={film.id} className={`film-card  ${horizontal ? 'horizontal' : 'vertical'}`} onMouseEnter={() => {
                                        onHover && onHover(film);
                                    }}>
                                        <img src={Routes.Domain + Routes.imagesFilm + film.film_banner} alt={film.film_name} />
                                        <span className='film-title'>{film.film_name}</span>
                                        <span className='film-rating'>{getFilmRating(film)}</span>
                                        <h1 className='film-views'>{film.views ? NumberFormat(film.views) : ""}</h1>
                                    </Link>
                                )}
                            </>
                        ))
                    )}
                    {films && (
                        films.map((filmB: any) => {
                            var film: IFilm

                            if (filmB.film) {
                                film = filmB.film
                            } else {
                                film = filmB
                            }

                            return (
                                <Link to={{ pathname: `/film/${film.id}` }} state={{ film: film }} key={film.id} className="film-card" onMouseEnter={() => {
                                    onHover && onHover(film);
                                }}>
                                    <img src={Routes.Domain + Routes.imagesFilm + film.film_banner} alt={film.film_name} />
                                    <span className='film-title'>{film.film_name}</span>

                                    {getFilmRating(film) !== "N/A" && (
                                        <>
                                            <span className='film-rating'>{getFilmRating(film)}</span>
                                            <h1 className='film-views'>{film.views ? NumberFormat(film.views) : ""}</h1>
                                        </>
                                    )}


                                </Link>
                            )
                        })
                    )}
                </>
            )}
        </>
    );
}

export default FilmCards;