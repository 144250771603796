import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import Button from "../../Design/Button/Button";

import { Helmet } from "react-helmet";

import "./Auth.css";

// Icons
import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import { useAuth } from "../../Components/AuthProvider";
import { Link, useNavigate } from "react-router-dom";
import { loginUserRequest } from "../../Utils/Requests/RequestRegister";
import { ISnackbar } from "../../Interfaces/Snackbar";
import { CustomizedSnackbars } from "../../Design/Snackbar/Snackbar";

export default function Login() {
    const { user, loginUser } = useAuth();
    const navigate = useNavigate();

    const [userData, setUserData] = useState({
        email: "",
        password: "",
    });

    const [snackBarData, setSnackBarData] = useState<ISnackbar>({
        message: '',
        messageType: '',
        openSnackbar: false,
        key: 0
    });

    const [errorMessages, setErrorMessages] = useState<{ [key: string]: string[] }>({
        email: [],
        password: [],
    });

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const loginUserAction = async (e: React.MouseEvent, callback: () => void) => {
        e.preventDefault();
        try {
            const response = await loginUserRequest(userData);
            if (response.status === "error") {
                if (response.errors) {
                    const newErrors: { [key: string]: string[] } = {};
                    Object.keys(response.errors).forEach((key) => {
                        newErrors[key] = response.errors[key];
                    });
                    setErrorMessages(newErrors);
                }

                if (response.message) {
                    setSnackBarData({
                        message: response.message,
                        messageType: "error",
                        openSnackbar: true,
                        key: new Date().getTime()
                    });
                }
            } else if (response.status === "success") {
                loginUser(response.token);
                navigate("/");
            }
        } catch (error) {
            console.log(error);
        }

        callback();
    };


    useEffect(() => {
        if (user.loggedIn) {
            navigate("/");
        }
    }, [user.loaded]);

    return (
        <>
            <Helmet>
                <title>Login</title>
                <meta name="title" content="Login" />
                <meta name="description" content="Login to FxFilms" />
                <meta name="keywords" content="films, fxfilms, amazon, netflix, free films, watch free films" />
                <meta property="og:title" content={"Login"} />
                <meta property="og:description" content="Login to FxFilms.net and watch free movies with friends" />
            </Helmet>
            <div className="auth-form-holder">
                <form className="login-form">
                    <h1>Login</h1>

                    <TextField
                        label="Email"
                        fullWidth
                        variant="standard"
                        required
                        InputProps={{
                            startAdornment: (
                                <EmailIcon sx={{ margin: "0 10px 0 0" }} />
                            ),
                        }}
                        type="email"
                        name="email"
                        onChange={onChange}
                        error={!!errorMessages.email.length}
                        helperText={errorMessages.email[0]}
                    />

                    <TextField
                        label="Password"
                        fullWidth
                        variant="standard"
                        required
                        type="password"
                        InputProps={{
                            startAdornment: (
                                <PasswordIcon sx={{ margin: "0 10px 0 0" }} />
                            ),
                        }}
                        name="password"
                        onChange={onChange}
                        error={!!errorMessages.password.length}
                        helperText={errorMessages.password[0]}
                    />

                    <Button
                        text="Login"
                        clicked={loginUserAction}
                        icon={undefined}
                    />

                    <br></br>
                    <span>Not a member  <Link to={"/Auth/Register"} style={{ color: "var(--primaryColor)" }}>Register</Link></span>
                    <br></br>
                    <Link to={"/Auth/ForgotPassword"} style={{ color: "var(--primaryColor)" }}>Forgot Password</Link>
                </form>
            </div>
            <CustomizedSnackbars messageType={snackBarData.messageType} message={snackBarData.message} openSnackbar={snackBarData.openSnackbar} key={snackBarData.key} />
        </>
    );
}
