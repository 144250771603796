import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IFilm } from '../../Interfaces/Film';
import { Skeleton } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

import Settings from "../../Utils/Routes.json"

import './Navbar.css';

// Interface
import { ISearchFilm } from '../../Interfaces/FilmSearch';
import { searchFilmDataRequest } from '../../Utils/Requests/RequestRegister';

// Navbar Component
import IconButton from '@mui/material/IconButton';
import { Avatar, Box, Menu, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import MovieIcon from '@mui/icons-material/Movie';
import SerialIcon from '@mui/icons-material/MovieCreation';
import HomeIcon from '@mui/icons-material/Home';
import AnimationIcon from '@mui/icons-material/AutoAwesomeMotion';
import SecurityIcon from '@mui/icons-material/Security';
import Button from '../Button/Button';
import FilmCards from '../FilmCard/FilmCard';
import { useAuth } from '../../Components/AuthProvider'
import { useDragScroll } from '../../Components/Dragger';
import TheatersIcon from '@mui/icons-material/Theaters';


import LoginIcon from '@mui/icons-material/Login';

interface ISettings {
    display: string;
    redirect: string;
    icon?: React.ReactElement<any>;
    action?: () => void | undefined;
}

function Navbar() {
    const { user, logOutUser } = useAuth();
    const navigation = useNavigate();
    const [searchResultDragger] = useDragScroll();
    const searchResultEl = useRef<HTMLDivElement | null>(null);
    const draggerRef = useRef<HTMLDivElement | null>(null);

    const location = useLocation();
    const { pathname } = location;

    const combinedRefCallback = useCallback(
        (node: HTMLDivElement) => {
            if (node) {
                searchResultEl.current = node;
                draggerRef.current = node;
                searchResultDragger(node);
            }
        },
        [searchResultDragger]
    );

    const [filmData, setFilmData] = useState<ISearchFilm>({
        film_name: "",
        page: 1,
        limit: 25,
        skeletonAmount: 20,
        token: user.token
    });

    const [filmListVertical, setFilmListVertical] = useState<boolean>(false);

    const [settings, setSettings] = useState<ISettings[]>([
        {
            display: 'Settings',
            redirect: "/user/settings",
            icon: <SettingsIcon className='settings-icon' />
        },
        {
            display: "History",
            redirect: "/user/history",
            icon: <HistoryIcon className='settings-icon' />
        },
        {
            display: "Logout",
            redirect: "logout",
            action: logOutUser,
            icon: <LogoutIcon className='settings-icon' />
        }
    ]);


    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [activeTabPosition, setActiveTabPosition] = useState<{ top: number, left: number, width: number, height: number }>({ top: 0, left: 0, width: 0, height: 0 });

    const tabElementClicked = (e: React.MouseEvent<HTMLAnchorElement>) => {
        const rect = e.currentTarget.getBoundingClientRect();
        setActiveTabPosition({ top: rect.top, left: rect.left, width: rect.width, height: activeTabPosition.height });
    };

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {

        if (searchResultEl.current === null) {
            return;
        }

        setFilmData((prevData) => {
            return {
                ...prevData,
                film_name: e.target.value,
            };
        });

        if (e.target.value.length === 0) {
            searchResultEl.current.style.display = "none";
            return;
        }
        searchResultEl.current.style.display = "flex";
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    useEffect(() => {
        var activeTab = document.querySelector(`.tab-element[href="${pathname}"]`);
        if (activeTab) {
            const rect = activeTab.getBoundingClientRect();
            const centeredLeft = rect.left + rect.width / 2 - 60; // Adjust 60 to half of the indicator's width
            setActiveTabPosition({
                top: rect.top - 50, // Adjust 10 to move a bit above
                left: centeredLeft - 60,
                width: rect.width,
                height: 5
            });
        } else {
            var activeTab = document.querySelector(`.tab-element[href="/"]`);
            if (activeTab) {
                const rect = activeTab.getBoundingClientRect();
                const centeredLeft = rect.left + rect.width / 2 - 60; // Adjust 60 to half of the indicator's width
                setActiveTabPosition({
                    top: rect.top - 50, // Adjust 10 to move a bit above
                    left: centeredLeft - 60,
                    width: rect.width,
                    height: 5
                });
            }
        }
        const handleResize = () => {
            const activeTab = document.querySelector(`.tab-element[href="${pathname}"]`);
            if (activeTab) {
                const rect = activeTab.getBoundingClientRect();
                const centeredLeft = rect.left + rect.width / 2 - 60; // Adjust 60 to half of the indicator's width
                setActiveTabPosition({
                    top: rect.top - 10, // Adjust 10 to move a bit above
                    left: centeredLeft,
                    width: rect.width,
                    height: 5
                });
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });

    useEffect(() => {
        setFilmData((prevData) => {
            return {
                ...prevData,
                film_name: ""
            };
        });

        if (searchResultEl.current) {
            searchResultEl.current.style.display = "none";
        }
    }, [window.location.href])

    useEffect(() => {
        setFilmData((prevData: ISearchFilm) => {
            return {
                ...prevData,
                token: user.token
            };
        });
    }, [user.loaded]);

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [pathname])

    return (
        <>
            <Helmet>
                {user.accountStatus !== "Admin" && (
                    <script type='text/javascript' src='//chancesarmlessimpulse.com/1e/e7/58/1ee7581bd5dcb9e02c44a68e4d2fc7e5.js'></script>
                )}
            </Helmet>
            <div className='navbar-space'></div>
            <div className='navbar'>
                <div className={'search-result disabled ' + (filmListVertical === true ? "horizontal" : "vertical")} ref={combinedRefCallback} style={{ display: "none" }}>
                    <FilmCards filmData={filmData} horizontal={filmListVertical}></FilmCards>
                </div>
                <div className='header-top container'>
                    <div className='spacer'></div>
                    <TextField
                        onChange={onSearch}
                        sx={{ margin: "0px 40px", width: "100%" }}
                        placeholder='Search'
                        InputProps={{
                            className: "search-bar"
                        }}
                        value={filmData.film_name}
                    />
                    <div className='user-info'>
                        {user.loggedIn ? (
                            <Box sx={{ flexGrow: 0 }}>
                                <Tooltip title="Open settings">
                                    <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} >
                                        <Avatar
                                            alt={user.firstName + " " + user.lastName}
                                            sx={{
                                                width: 48, // Set the width to your desired size
                                                height: 48, // Set the height to your desired size
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    sx={{ mt: '55px', }}
                                    anchorEl={anchorElUser}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorElUser)}
                                    onClose={handleCloseUserMenu}
                                >
                                    {user.accountStatus === "Admin" && (
                                        <MenuItem key={"admin"} onClick={(e) => {
                                            navigation("admin");
                                            handleCloseUserMenu();
                                        }}>
                                            <SecurityIcon className='settings-icon' />
                                            <Typography textAlign="end" sx={{ color: "white" }}>{" Admin"}</Typography>
                                        </MenuItem>
                                    )}
                                    {settings.map((setting) => (
                                        <MenuItem key={setting.display} onClick={(e) => {
                                            setting.action && setting.action();
                                            handleCloseUserMenu();
                                        }}>
                                            {setting.icon}
                                            <Typography textAlign="end" sx={{ color: "white" }}>{setting.display}</Typography>
                                        </MenuItem>
                                    ))}
                                </Menu>
                            </Box>
                        ) : (
                            <Button text='' clicked={(e, callback) => {
                                navigation("auth/login");
                                callback();
                            }} icon={LoginIcon}></Button>
                        )}
                    </div>
                </div>
                {/* <svg className='bottom-navbar-indicator' style={{ left: activeTabPosition.left, color: "currentColor" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 600 600" shape-rendering="geometricPrecision" text-rendering="geometricPrecision"><ellipse rx="60" ry="60" transform="matrix(1.647542 0 0 1.647542 299.492887 288.028489)" fill="currentColor" stroke-width="0" /><path d="M201.14748,300v98.85252h-102.347621C144.174494,396.15726,201.283274,355.504434,201.14748,300Z" transform="matrix(1.750481 0 0 1.391498-150.321669-147.126479)" fill="#fff" stroke="#f6f6f6" stroke-width="0" /><path d="M201.14748,300v98.85252h-102.347621C144.174494,396.15726,201.283274,355.504434,201.14748,300Z" transform="matrix(-1.75 0 0 1.391498 749.339397-147.126479)" fill="#fff" stroke="0" stroke-width="0" /><rect width="197.70504" height="98.85252" rx="0" ry="0" transform="matrix(.99487 0 0 1.20662 200.640367 288.039959)" fill="#fff" stroke="0" /></svg> */}

            </div>
            <div className='header-bottom'>
                <Link className={`tab-element ${pathname === '/' ? 'active' : ''}`} onClick={tabElementClicked} to="/">
                    <HomeIcon />
                </Link>
                <Link className={`tab-element ${pathname === '/films' ? 'active' : ''}`} onClick={tabElementClicked} to="/films">
                    <MovieIcon />
                </Link>
                <Link className={`tab-element ${pathname === '/series' ? 'active' : ''}`} onClick={tabElementClicked} to="/series">
                    <TheatersIcon />
                </Link>
            </div>
        </>
    );
}

export default Navbar;