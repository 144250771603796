import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";
import Button from "../../Design/Button/Button";

import { Helmet } from "react-helmet";

import "./Auth.css";

import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import PersonIcon from '@mui/icons-material/Person';
import { registerUserRequest } from "../../Utils/Requests/RequestRegister";
import { useAuth } from "../../Components/AuthProvider";
import { CustomizedSnackbars } from "../../Design/Snackbar/Snackbar";
import { ISnackbar } from "../../Interfaces/Snackbar";
import { Link, useNavigate } from "react-router-dom";

export default function Register() {

    const { user, loginUser } = useAuth()

    const navigate = useNavigate()

    const [userData, setUserData] = useState({
        email: "",
        password: "",
        lastname: "",
        firstname: "",
    });

    const [snackBarData, setSnackBarData] = useState<ISnackbar>({
        message: '',
        messageType: '',
        openSnackbar: false,
        key: 0
    })

    const [errorMessages, setErrorMessages] = useState<{ [key: string]: string[] }>({
        email: [],
        password: [],
        lastname: [],
        firstname: [],
    });

    const [activeInput, setActiveInput] = useState<string | null>(null);

    const registerUser = async (e: React.MouseEvent, callback: () => void) => {
        e.preventDefault();
        try {
            const response = await registerUserRequest(userData);
            if (response.status === "error") {
                if (response.errors) {
                    const newErrors: { [key: string]: string[] } = {};
                    Object.keys(response.errors).forEach((key) => {
                        newErrors[key] = response.errors[key];
                    });
                    setErrorMessages(newErrors);
                }

                if (response.message) {
                    setSnackBarData({
                        message: response.message,
                        messageType: "error",
                        openSnackbar: true,
                        key: new Date().getTime()
                    })
                }
            } else if (response.status === "success") {
                loginUser(response.token)

                navigate("/Home")
            }
        } catch (error) {
            console.log(error);
        }

        callback()
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserData({ ...userData, [e.target.name]: e.target.value });
    };

    const handleFocus = (inputName: string) => {
        setActiveInput(inputName);
    };

    const handleBlur = () => {
        setActiveInput(null);
    };

    useEffect(() => {
        if (user.loggedIn) {
            navigate("/Home")
        }
    }, [user.loaded])

    return (
        <>
            <Helmet>
                <title>Register</title>
                <meta name="title" content="Register" />
                <meta name="description" content="Register to FxFilms" />
                <meta name="keywords" content="films, fxfilms, amazon, netflix, free films, watch free films" />
                <meta property="og:title" content={"Register"} />
                <meta property="og:description" content="Register to FxFilms.net and watch free movies with friends" />
            </Helmet>
            <div className="auth-form-holder">
                <form className="login-form">
                    <h1>Register</h1>

                    <TextField
                        label="Firstname"
                        fullWidth
                        variant="standard"
                        required
                        InputProps={{
                            startAdornment: (
                                <PersonIcon sx={{ margin: "0 10px 0 0", color: activeInput === "firstname" ? "var(--primaryColor)" : "inherit" }} />
                            ),
                        }}
                        type="text"
                        name="firstname"
                        onChange={onChange}
                        onFocus={() => handleFocus("firstname")}
                        onBlur={handleBlur}
                        error={!!errorMessages.firstname}
                        helperText={errorMessages.firstname?.[0]}
                    />

                    <TextField
                        label="Lastname"
                        fullWidth
                        variant="standard"
                        required
                        InputProps={{
                            startAdornment: (
                                <PersonIcon sx={{ margin: "0 10px 0 0", color: activeInput === "lastname" ? "var(--primaryColor)" : "inherit" }} />
                            ),
                        }}
                        type="text"
                        name="lastname"
                        onChange={onChange}
                        onFocus={() => handleFocus("lastname")}
                        onBlur={handleBlur}
                        error={!!errorMessages.lastname}
                        helperText={errorMessages.lastname?.[0]}
                    />

                    <TextField
                        label="Email"
                        fullWidth
                        variant="standard"
                        required
                        InputProps={{
                            startAdornment: (
                                <EmailIcon sx={{ margin: "0 10px 0 0", color: activeInput === "email" ? "var(--primaryColor)" : "inherit" }} />
                            ),
                        }}
                        type="email"
                        name="email"
                        onChange={onChange}
                        onFocus={() => handleFocus("email")}
                        onBlur={handleBlur}
                        error={!!errorMessages.email}
                        helperText={errorMessages.email?.[0]}
                    />

                    <TextField
                        label="Password"
                        fullWidth
                        variant="standard"
                        required
                        type="password"
                        InputProps={{
                            startAdornment: (
                                <PasswordIcon sx={{ margin: "0 10px 0 0", color: activeInput === "password" ? "var(--primaryColor)" : "inherit" }} />
                            ),
                        }}
                        name="password"
                        onChange={onChange}
                        onFocus={() => handleFocus("password")}
                        onBlur={handleBlur}
                        error={!!errorMessages.password}
                        helperText={errorMessages.password?.[0]}
                    />

                    <Button
                        text="Register"
                        clicked={registerUser}
                        icon={undefined}
                    />

                    <span>Or</span>
                    <Link to={"/Auth/Login"}>Login</Link>
                </form>
            </div>
            <CustomizedSnackbars messageType={snackBarData.messageType} message={snackBarData.message} openSnackbar={snackBarData.openSnackbar} key={snackBarData.key} />
        </>
    );
}
