import React, { useEffect, useState } from 'react';
import { ISearchFilm } from '../../Interfaces/FilmSearch';
import { useAuth } from '../../Components/AuthProvider';
import { TablePagination } from '@mui/material';
import FilmCards from '../../Design/FilmCard/FilmCard';
import { searchFilmDataRequest } from '../../Utils/Requests/RequestRegister';
import { IFilm } from '../../Interfaces/Film';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function Series() {
  const { user } = useAuth();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [films, setFilms] = useState<IFilm[]>([]);
  const [totalFilms, setTotalFilms] = useState<number>(0);

  const getFilms = async () => {
    try {
      const filmName = searchParams.get('film_name') || '';
      const id = searchParams.get('id') || '';
      const playlist = 'true';
      const watched = searchParams.get('watched') || '';
      const liked = searchParams.get('liked') || '';
      const sort = searchParams.get('sort') || '';
      const filmCountry = searchParams.get('film_country') || '';
      const filmPlaylist = searchParams.get('film_playlist') || '';
      const filmGenre = searchParams.get('film_genre') || '';
      const token = user.token;

      const result = await searchFilmDataRequest(`?film_name=${filmName}&id=${id}&page=${page + 1}&limit=${rowsPerPage}&playlist=${playlist}&watched=${watched}&liked=${liked}&sort=${sort}&film_country=${filmCountry}&film_playlist=${filmPlaylist}&film_genre=${filmGenre}&token=${token}`);

      if (result.success === false) {
        console.log(result.message);
        return;
      }

      const filmSort = result.films.map((film: IFilm) => {
        return { film: film };
      });

      setFilms(filmSort);
      setTotalFilms(result.count);
    } catch (error: any) {
      console.log(error);
    }
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    getFilms();
  }, [page, rowsPerPage, location.search]); // Include location.search to refetch when query params change

  return (
    <>
      <Helmet>
        <title>Search Series</title>
      </Helmet>
      <script async data-cfasync="false" src="//chancesarmlessimpulse.com/02df13ae05471911ea5534d38ff621d4/invoke.js"></script>
      <div className='container'>
        <div className='films-list-container'>
          <div className='films-list'>
            <FilmCards films={films} horizontal={true} />
          </div>
          <div id="container-02df13ae05471911ea5534d38ff621d4"></div>
          <TablePagination
            component="div"
            count={totalFilms}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{ color: "var(--secondaryColor)" }}
            style={{ width: "fit-content", margin: "auto" }}
          />
        </div>
      </div>
    </>
  );
}

export default Series;
