import React, { useState } from 'react';
import './Banner.css';

import FilmCards from '../FilmCard/FilmCard';
import { useDragScroll } from '../../Components/Dragger';
import { ISearchFilm } from '../../Interfaces/FilmSearch';
import { IFilm } from '../../Interfaces/Film';

import Settings from "../../Utils/Routes.json"
import { Link } from 'react-router-dom';

function Banner() {
    const [hoveredFilm, setHoveredFilm] = useState<IFilm>();
    const [backgroundImage, setBackgroundImage] = useState<string>('')

    const filmData: ISearchFilm = {
        page: 1,
        limit: 9,
        skeletonAmount: 9,
        sort: "new",
        
    }

    const [draggerRef] = useDragScroll();

    const handleFilmHover = (film: IFilm) => {
        setBackgroundImage(`url(${Settings.Domain + Settings.imagesFilm + film.film_banner})`)
        setHoveredFilm(film);
    };


    return (
        <div className='banner-skeleton' style={{ backgroundImage: hoveredFilm ? backgroundImage : "", backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
            <div className='spacer'>

            </div>
            <div className='content' ref={draggerRef}>
                <FilmCards filmData={filmData} horizontal={true} className="banner" onHover={handleFilmHover} />
            </div>
            <div className='shadow'></div>
        </div>
    )
}

export default Banner;