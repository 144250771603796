import React, { useState } from 'react'

import Banner from '../../Design/Banner/Banner'

import "./Home.css"
import FilmCards from '../../Design/FilmCard/FilmCard'
import { ISearchFilm } from '../../Interfaces/FilmSearch'
import { useDragScroll } from '../../Components/Dragger'
import { Helmet } from 'react-helmet'
import AdScriptLoader from '../../Components/AdReloader';


function Home() {



  const [filmData, setFilmData] = useState<ISearchFilm[]>([
    {
      sort: "new",
      page: 1,
      limit: 25,
      skeletonAmount: 9,
    },
    {
      sort: "views",
      page: 1,
      limit: 25,
      skeletonAmount: 9
    },
    {
      sort: "watched",
      page: 1,
      limit: 25,
      skeletonAmount: 9
    }
  ])

  const [newRefDragger] = useDragScroll()
  const [famouseRefDragger] = useDragScroll()
  const [commingSoonRefDragger] = useDragScroll()

  return (
    <>
      <Banner />
      <Helmet>
        <title>FxFilms - Watch Free Movies Online</title>
        <meta name="description" content="Welcome to FxFilms! Explore and watch a diverse range of free movies online. Enjoy streaming films from various genres with friends and family." />
        <meta name="keywords" content="films, FxFilms, free movies, watch movies, film streaming, cinema, movie nights" />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="FxFilms - Watch Free Movies Online" />
        <meta property="og:description" content="Discover and enjoy a wide selection of free movies on FxFilms. Stream films of all genres and share the experience with others." />
        <meta property="og:url" content="https://fxfilms.net" />
      </Helmet>
      <script async data-cfasync="false" src="//chancesarmlessimpulse.com/02df13ae05471911ea5534d38ff621d4/invoke.js"></script>
      <div className='container film-list'>
        <div className='film-classes'>
          <div className='header-title'>
            <p>Watched</p>
          </div>
          <div className='film-card-list' ref={newRefDragger}>
            <FilmCards filmData={filmData[2]} horizontal={true} continueLoading={true}></FilmCards>
          </div>
        </div>


        <div className='film-classes'>
          <div className='header-title'>
            <p>New</p>
          </div>
          <div className='film-card-list' ref={newRefDragger}>
            <FilmCards filmData={filmData[0]} horizontal={true} continueLoading={true}></FilmCards>
          </div>
        </div>

        <div className='film-classes'>
          <div className='header-title'>
            <p>Famous</p>
          </div>
          <div className='film-card-list' ref={famouseRefDragger}>
            <FilmCards filmData={filmData[1]} horizontal={true} continueLoading={true}></FilmCards>
          </div>
        </div>

        <div id="container-02df13ae05471911ea5534d38ff621d4"></div>
      </div>
    </>
  )
}

export default Home